<template>
    <div style="height: 100vh" class="survey-box">
        <div class="survey" :style="{transform: `translate3d(-${moduleActive*100}%, 0px, 0px)`}">
            <!--首页模块-->
            <div class="transition-page flex-column-center" style="justify-content: center">
                <div class="transition-home" v-show="moduleActive === 0">
                    <div class="sub-color home-title">
                        工作 | 生活 | 饮食
                    </div>
                    <div class="home-title">
                        <p>「更好地了解自己</p>
                        <p style="margin-left:15px">更好地管理健康」</p>
                    </div>
                    <ul>
                        <li><span style="font-size: 18px;margin-right: 10px">•</span>完成本套问卷大概需要<span class="sub-color">3-4分钟</span></li>
                        <li><span style="font-size: 18px;margin-right: 10px">•</span>问卷由 UitaUita<span class="sub-color">全球专业团队</span>设计，其中领导者：</li>
                    </ul>
                    <div class="home-preson">
                        <p>营养学博士：</p>
                        <p>凯瑟琳·斯·格瓦西奥<span>（英国）</span></p>
                    </div>
                    <div class="home-preson">
                        <p>营养学教授：</p>
                        <p>杰西·罗珀<span>（澳大利亚）</span></p>
                    </div>
                    <div class="home-preson">
                        <p>营养基因学、营养遗传学专家：</p>
                        <p>布伦达·佩拉塔·罗梅罗<span>（美国）</span></p>
                    </div>
                    <div class="home-preson">
                        <p>心理学专家、研究所高级营养师：</p>
                        <p>莎拉·索米安<span>（加拿大）</span></p>
                    </div>
                </div>
                <div class="home-btn" @click="homeNext" v-show="moduleActive === 0">开始了解你的身体</div>
                <van-checkbox
                        v-model="isPrivacy"
                        v-show="moduleActive === 0"
                        checked-color="#c270ff">
                    <span @click="openPopup">
                      同意<u>《UitaUita隐私协议》</u>
                    </span>

                </van-checkbox>
            </div>

            <!--标题模块1-->
            <div class="transition-page flex-column-center" style="justify-content: center">
                <div class="home-title sub-color transition-item home2" >
                    UitaUita &「U」
                </div>
                <ul class="flex-column-center module-list">
                    <li class="flex-center transition-item" style="animation-delay: 0.2s" v-show="moduleActive === 1">
                        <p class="sub-color">工作 | 生活</p>
                    </li>

                    <li class="flex-center transition-item" style="animation-delay: 0.4s" v-show="moduleActive === 1">
                        <p class="sub-color ">饮食｜习惯</p>
                    </li>
                    <li class="flex-center transition-item" style="animation-delay: 0.6s" v-show="moduleActive === 1">
                        <p class="sub-color ">身体｜状态</p>
                    </li>
                </ul>
            </div>


            <!--题目模块1-->
            <div class="answer-page ">
                <!--进度条-->
                <div class="progress-box flex-between">
                    <div class="flex" @click="lastStep(0)">
                        <van-icon name="arrow-left"/>
                        <span>上一题</span>
                    </div>
                    <div class="progress">
                        <div class="active-progress" :style="{width:(progress) + '%'}"></div>
                    </div>
                    <span>{{ progress }}%</span>
                </div>
                <div class="survey-box">
                    <div class="survey" :style="{transform: `translateX(-${pageActive[0]*100}%)`}">
                        <div class="problem flex-column-center" v-for="(item,index) in filterList(0)" :key="index">
                            <!--for循环遍历题目分行-->
                            <div style="margin-bottom: 30px" class="flex-column-center">
                                <div
                                        class="title flex-column-center"
                                        :style="{textAlign:item.title.indexOf('<br/>') === -1 && item.title.length>15 ? 'left': 'center'}"
                                        v-html='item.title.replace("xxx", name).replace("zzz", sexName)'>
                                </div>
                                <div class="sub-title" v-if="item.sub_title" v-html="item.sub_title">

                                </div>
                            </div>

                            <!--判断是否输入框-->
                            <div class="problem-list flex-column-center" v-if="item.input">
                                <div class="flex" style="width: 75%">
                                    <div style="position: relative" class="flex">
                                        <input class="input" placeholder="请输入" :type="item.input"
                                               :value="$$(answerList[item.id],'answer_id')"
                                               @change="inputValue($event,item)"
                                               @keyup=" inputKeyUp($event,item)"/>
                                        <!--判断单位-->
                                        <div class="unit" v-if="$$(item.answer,0,'unit')">
                                            {{ $$(item.answer, 0, 'unit') }}
                                        </div>
                                    </div>
                                    <van-icon name="checked" color="#c270ff" size="40px"
                                              @click="inputNext(0,item.id,item.answer[0])" style="margin-top: 60px"/>
                                </div>
                                <div class="unit-tion">
                                    <span v-if="item.id === 4 && $$(answerList[item.id], 'answer_id')[0]">
                                        {{ $$(answerList[item.id], 'answer_id')[0] | height }}
                                    </span>

                                    <span v-if="item.id === 5 && $$(answerList[item.id], 'answer_id')[0]">
                                        {{ $$(answerList[item.id], 'answer_id')[0] | width }}
                                    </span>

                                </div>

                            </div>

                            <!--单选球体-->
                            <div class="problem-list flex-warp" v-if="item.type ===1 && !item.input">
                                <div
                                        class="problem-item flex-center"
                                        :class="{'problem-active':$$(answerList[item.id],'answer_id').includes(answerItem.answer_id),' problem-radio': item.style === 1}"
                                        v-for="(answerItem,index) in item.answer"
                                        :key="index"
                                        @click="handRadio(item,answerItem,0)">
                                    <p v-html="answerItem.text"></p>
                                </div>
                            </div>

                            <div class="tag" v-if="item.tag">
                                {{ item.tag }}
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <!--标题二模块-->
            <div class="transition-page flex-column-center" style="justify-content: center">
                <!--过渡页文字分行-->
                <div class="transition-item home-title sub-color home2" style="animation-delay:0s;margin-top: -38vh"
                     v-show="moduleActive === 3">
                    UitaUita &「U」
                </div>
                <div class="transition-item  " style="animation-delay:0.2s" v-show="moduleActive === 3">
                    好啦，<strong>{{ name }}</strong>，我们互相认识啦
                </div>
                <div class="transition-item  " style="animation-delay:0.4s" v-show="moduleActive === 3">
                    接下来，我将进入你的世界
                </div>
                <div
                        class="transition-item "
                        style="animation-delay:0.4s;margin-top: 20px;font-family: fantasy;font-weight: 700"
                        v-show="moduleActive === 3">
                    更好的认识自己，也可以<span class="sub-color yellow">更健康的生活哟</span>
                </div>
            </div>

            <!--提莫模块2-->
            <div class="answer-page ">
                <!--进度条-->
                <div class="progress-box flex-between">
                    <div class="flex" @click="lastStep(1)">
                        <van-icon name="arrow-left"/>
                        <span>上一题</span>
                    </div>
                    <div class="progress">
                        <div class="active-progress" :style="{width:(progress ) + '%'}"></div>
                    </div>
                    <span>{{ progress }}%</span>
                </div>
                <div class="survey-box">
                    <div class="survey" :style="{transform: `translateX(-${pageActive[1]*100}%)`}">
                        <div class="problem flex-column-center" v-for="(item,index) in filterList(1)" :key="index">
                            <svg class="icon-svg" aria-hidden="true">
                                <use :xlink:href="'#'+item.sub_icon"></use>
                            </svg>
                            <!--for循环遍历题目分行-->
                            <div style="margin-bottom: 30px" class="flex-column-center">
                                <div
                                        class="title flex-column-center"
                                        :style="{textAlign:item.title.indexOf('<br/>') === -1 && item.title.length>15 ? 'left': 'center'}"
                                        v-html='item.title.replace("xxx", name).replace("zzz", sexName)'>
                                </div>
                                <div class="sub-title" v-if="item.sub_title" v-html="item.sub_title">

                                </div>

                                <div v-if="item.type ===2" style="margin-top: 10px">(多选)</div>
                            </div>

                            <!--是否展示banner图-->
                            <div class="banner flex-center" v-if="item.banner">
                                <van-image width="100%" :src="item.banner"/>
                            </div>

                            <!--单选球体-->
                            <div class="problem-list flex-warp" v-if="item.type ===1 && !item.input">
                                <div
                                        class="problem-item flex-center"
                                        :class="{'problem-active':$$(answerList[item.id],'answer_id').includes(answerItem.answer_id),' problem-radio': item.style === 1}"
                                        v-for="(answerItem,index) in item.answer"
                                        :key="index"
                                        @click="handRadio(item,answerItem,1)">
                                    <p v-html="answerItem.text"></p>
                                </div>
                            </div>

                            <!--多选-->
                            <div class="problem-list flex-warp" v-if="item.type ===2">
                                <div
                                        class="problem-item  flex-center"
                                        :class="{'problem-active':$$(answerList[item.id],'answer_id').includes(answerItem.answer_id),'problem-select':item.style === 3 && answerItem.text.length < 4}"
                                        v-for="(answerItem,index) in item.answer"
                                        :key="index"
                                        @click="handCheckbox(item,answerItem,1)">
                                    <img
                                            :src="$$(answerList[item.id],'answer_id').includes(answerItem.answer_id) ? answerItem.a_icon : answerItem.icon"
                                            class="icon"
                                            v-if="answerItem.icon"/>
                                    <div v-html="answerItem.text"></div>
                                </div>
                                <div class="btn" @click="checkBtn(item.id,1)">确定</div>
                            </div>

                            <div class="tag" v-if="item.tag">
                                {{ item.tag }}
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <!--标题三模块-->
            <div class="transition-page flex-column-center" style="justify-content: center">
                <div class="transition-item home-title sub-color home2" style="animation-delay:0s;margin-top: -38vh"
                     v-show="moduleActive === 5">
                    UitaUita &「U」
                </div>
                <div class="transition-item" style="animation-delay:0.2s"
                     v-show="moduleActive === 5">
                    非常好，<strong>{{name}}</strong>
                </div>
                <div class="transition-item" style="animation-delay:0.4s"
                     v-show="moduleActive === 5">
                    我们刚刚完成了<span class="sub-color blue">工作｜生活</span>
                </div>
                <div class="transition-item" style="animation-delay:0.6s"
                     v-show="moduleActive === 5">
                    Little More，还有一点点
                </div>
                <div class="transition-item" style="animation-delay:0.8s"
                     v-show="moduleActive === 5">
                    我们来完成一下<span class="sub-color red">饮食｜习惯</span>
                </div>
            </div>

            <!--题目3-->
            <div class="answer-page ">
                <!--进度条-->
                <div class="progress-box flex-between">
                    <div class="flex" @click="lastStep(2)">
                        <van-icon name="arrow-left"/>
                        <span>上一题</span>
                    </div>
                    <div class="progress">
                        <div class="active-progress" :style="{width:(progress ) + '%'}"></div>
                    </div>
                    <span>{{ progress }}%</span>
                </div>
                <div class="survey-box">
                    <div class="survey" :style="{transform: `translateX(-${pageActive[2]*100}%)`}">
                        <div class="problem flex-column-center" v-for="(item,index) in filterList(2)" :key="index">
                            <svg class="icon-svg" aria-hidden="true">
                                <use :xlink:href="'#'+item.sub_icon"></use>
                            </svg>
                            <!--for循环遍历题目分行-->
                            <div style="margin-bottom: 30px" class="flex-column-center">
                                <div
                                        class="title flex-center"
                                        :style="{textAlign:item.title.indexOf('<br/>') === -1 && item.title.length>15 ? 'left': 'center'}"
                                        v-html='item.title.replace("xxx", name).replace("zzz", sexName)'>
                                </div>
                                <div class="sub-title" v-if="item.sub_title" v-html="item.sub_title">

                                </div>
                                <div v-if="item.type ===2" style="margin-top: 10px">(多选)</div>
                            </div>

                            <!--是否展示banner图-->
                            <div class="banner flex-center" v-if="item.banner">
                                <van-image width="100%" :src="item.banner"/>
                            </div>

                            <!--单选球体-->
                            <div class="problem-list flex-warp" v-if="item.type ===1 && !item.input">
                                <div
                                        class="problem-item flex-center"
                                        :class="{'problem-active':$$(answerList[item.id],'answer_id').includes(answerItem.answer_id),' problem-radio': item.style === 1,'problem-select':item.style === 3}"
                                        v-for="(answerItem,index) in item.answer"
                                        :key="index"
                                        @click="handRadio(item,answerItem,2)">
                                    <p v-html="answerItem.text"></p>
                                </div>
                            </div>

                            <!--多选-->
                            <div class="problem-list flex-warp" v-if="item.type ===2">
                                <div
                                        class="problem-item  flex-center"
                                        :class="{'problem-active':$$(answerList[item.id],'answer_id').includes(answerItem.answer_id),'problem-select':item.style === 3 && answerItem.text.length < 4}"
                                        v-for="(answerItem,index) in item.answer"
                                        :key="index"
                                        @click="handCheckbox(item,answerItem,2)">
                                    <img
                                            :src="$$(answerList[item.id],'answer_id').includes(answerItem.answer_id) ? answerItem.a_icon : answerItem.icon"
                                            class="icon"
                                            v-if="answerItem.icon"/>
                                    <div v-html="answerItem.text"></div>
                                </div>
                                <div class="btn" @click="checkBtn(item.id,2)">确定</div>
                            </div>

                            <div class="tag" v-if="item.tag">
                                {{ item.tag }}
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <!--标题第四模块-->
            <div class="transition-page flex-column-center" style="justify-content: center">
                <div class="transition-item home-title sub-color home2" style="animation-delay:0s;margin-top: -52vh"
                     v-show="moduleActive === 7">
                    UitaUita &「U」
                </div>
                <div class="transition-item " style="animation-delay:0.2s"
                     v-show="moduleActive === 7">
                    最后，还有一点你需要告诉我们
                </div>
                <div class="transition-item " style="animation-delay:0.4s"
                     v-show="moduleActive === 7">
                    这些至关重要，它是<span class="sub-color yellow">身体｜状态</span>
                </div>
            </div>

            <!--题目4-->
            <div class="answer-page ">
                <!--进度条-->
                <div class="progress-box flex-between">
                    <div class="flex" @click="lastStep(3)">
                        <van-icon name="arrow-left"/>
                        <span>上一题</span>
                    </div>
                    <div class="progress">
                        <div class="active-progress" :style="{width:(progress ) + '%'}"></div>
                    </div>
                    <span>{{ progress }}%</span>
                </div>
                <div class="survey-box">
                    <div class="survey" :style="{transform: `translateX(-${pageActive[3]*100}%)`}">
                        <div class="problem flex-column-center" v-for="(item,index) in filterList(3)" :key="index">
                            <!--for循环遍历题目分行-->
                            <div style="margin-bottom: 30px" class="flex-column-center">
                                <div
                                        class="title flex-center"
                                        :style="{textAlign:item.title.indexOf('<br/>') === -1 && item.title.length>15 ? 'left': 'center'}"
                                        v-html='item.title.replace("xxx", name).replace("zzz", sexName)'>
                                </div>
                                <div class="sub-title" v-if="item.sub_title" v-html="item.sub_title">

                                </div>
                                <div v-if="item.type ===2" style="margin-top: 10px">(多选)</div>
                            </div>


                            <!--是否展示banner图-->
                            <div class="banner flex-center" v-if="item.banner">
                                <van-image width="100%" :src="item.banner"/>
                            </div>

                            <!--单选球体-->
                            <div class="problem-list flex-warp" v-if="item.type ===1 && !item.input">
                                <div
                                        class="problem-item flex-center"
                                        :class="{'problem-active':$$(answerList[item.id],'answer_id').includes(answerItem.answer_id),' problem-radio': item.style === 1,'problem-select':item.style === 3}"
                                        v-for="(answerItem,index) in item.answer"
                                        :key="index"
                                        @click="handRadio(item,answerItem,3)">
                                    <p v-html="answerItem.text"></p>
                                </div>
                            </div>

                            <!--多选-->
                            <div class="problem-list flex-warp" v-if="item.type ===2">
                                <div
                                        class="problem-item  flex-center"
                                        :class="{'problem-active':$$(answerList[item.id],'answer_id').includes(answerItem.answer_id),'problem-select':item.style === 3 && answerItem.text.length < 7}"
                                        v-for="(answerItem,index) in item.answer"
                                        :key="index"
                                        @click="handCheckbox(item,answerItem,3)">
                                    <img
                                            :src="$$(answerList[item.id],'answer_id').includes(answerItem.answer_id) ? answerItem.a_icon : answerItem.icon"
                                            class="icon"
                                            v-if="answerItem.icon"/>
                                    <div v-html="answerItem.text"></div>
                                </div>
                                <div class="btn" @click="checkBtn(item.id,3)">确定</div>
                            </div>

                            <div class="tag" v-if="item.tag">
                                {{ item.tag }}
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <!--标题第5模块-->
            <div class="transition-page flex-column-center" style="justify-content: center">
                <div class="transition-item home-title sub-color home2" style="animation-delay:0s;margin-top: -23vh"
                     v-show="moduleActive === 9">
                    UitaUita &「U」
                </div>
                <div class="transition-item " style="animation-delay:0.2s;margin-top: 30px"
                     v-show="moduleActive === 9">
                    <strong>恭喜</strong>
                </div>
                <div class="transition-item " style="animation-delay:0.4s"
                     v-show="moduleActive === 9">
                    你已经全部完成了
                </div>
                <div class="transition-item " style="animation-delay:0.6s;margin-top: 30px"
                     v-show="moduleActive === 9">
                   <div class="home-btn" style="padding: 10px 30px;margin-top: 80px;width: 45vw;text-align: center" @click="surveyEnd">点击查看结果</div>
                </div>
            </div>

        </div>

        <van-popup v-model="showPopup" position="bottom" :style="{ height: '60vh' }" round closeable>
            <div class="rule-con">
                <div class="rule-title">UitaUita隐私政策</div>
                <div class="rule-info" v-html="agreement">

                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
    // import mockJson from "@/http/answier"
    import {Toast} from "vant";
    import agreement from "@/http/agreement"
    export default {
        name: "",
        created() {
			let adviser_id = this.$route.query.adviser_id
			let is_sp = this.$route.query.is_sp
            this.agreement = agreement
            this.$request.get(
                "survey",{
					adviser_id:adviser_id || 0,
					is_sp:is_sp || 0
				}
            ).then(res => {
                if(res.data.have_survey == 1){
                    this.$router.push({
                        path:'/my'
                    })
                }
                this.data = res.data

                // this.data = mockJson
                //初始化，存储数据
                this.data.category.forEach((item) => {
                    let arr = []
                    this.data.data.forEach((values) => {
                        if (values.kind === item.kind) {
                            arr.push(values)
                        }
                    })
                    if (arr.length > 0) {
                        this.list.push(arr)
                    }
                })
                // console.log(this.list)
                //取出本地数据，判断要不要重新开始
                let localStorage = window.localStorage.getItem("answerData")
                if (localStorage) {
                    let data = JSON.parse(localStorage)
                    this.moduleActive = data.moduleActive,
                        this.pageActive = data.pageActive,
                        this.name = data.name,
                        this.answerList = data.answerList,
                        this.progress = data.progress
                    this.answerIndex = data.answerIndex
                    this.deleteList = data.deleteList
                    this.$dialog.confirm({
                        title: '提示',
                        message: '是否继续填写问卷',
                    }).then(() => {
                        console.log('继续填写')
                        //根据存储的删除数据，进行数据处理
                        this.deleteList.forEach((item) => {
                            this.list[item.page].map((value) => {
                                if (value.id == item.delete_id) {
                                    value.is_delete = item
                                }
                                return item
                            })
                        })

                    }).catch(() => {
                        this.moduleActive = 0
                        this.pageActive = {0: 0, 1: 0, 2: 0, 3: 0}
                        this.name = ''
                        this.answerList = {}
                        this.progress = 0
                        this.answerIndex = 0
                        this.deleteList = []
                        window.localStorage.setItem("answerData", '')
                    })
                }
            })
        },

        data() {
            return {
                moduleActive:0,
                agreement:'',
                pageActive: { //题目模块内的active
                    0: 0,
                    1: 0,
                    2: 0,
                    3: 0,
                },
                name: 'ss',
                data: {},//全部题目
                list: [], //分类后题目
                answerList: {},//答案列表
                progress: 0,//进度条,
                answerIndex: 0,//已答题数量
                deleteList: [], //存储被删的题目
                isPrivacy: true,//协议勾选,
                showPopup:false
            };
        },
        computed: {
            sexName() {
                if (this.answerList[2]?.answer_text.includes('男')) {
                    return '帅哥'
                } else {
                    return '美女'
                }
            },
            filterList() {
                return (page) => {
                    return this.list[page]?.filter(item => {
                        //0是存在，其他是删除
                        if (!item.is_delete || item.is_delete == 0) {
                            return item
                        }
                    })
                }
            }
        },
        methods: {
            openPopup(){
              this.showPopup = true
                this.isPrivacy =! this.isPrivacy
            },
            //首页下一步
            homeNext() {
                console.log(this.data.kind)
                if(!this.data.data){

                    Toast('问卷加载中')
                }
                else if (this.isPrivacy ) {
                    this.moduleActive = 1
                }else{
                    Toast('您还未同意《UitaUita隐私协议》')
                }
            },
            // 问卷结束跳转
            surveyEnd(){
                let length = (this.data.category.length) * 2  + 1
                if (length == this.moduleActive) {
                    const toast = Toast.loading({
                        message: '报告生成中',
                        forbidClick: true,
                    })
                    window.localStorage.removeItem("answerData")
                    this.$request.post("question_result", {
                        question_list: JSON.stringify(this.answerList)
                    }).then(res => {
                        if (res.code === 1) {
                            window.sessionStorage.setItem("answer", JSON.stringify(res.data))
                            toast.clear()
                            this.$router.push({
                                path: '/mass'
                            })
                        }else{
                            Toast(res.message)
                        }
                    })
                }
            },
            //限制输入框
            inputKeyUp(e, item) {
                //称呼
                if (item.id === 1) {

                    e.target.value = e.target.value.replace(/[^a-zA-z\u4e00-\u9fa5\s]/g, '')
                }  else if (item.id === 4 || item.id === 5) {
                    e.target.value = e.target.value.replace(/[^\d^\.]/g, '')
                }
                this.inputValue(e, item)
            },
            // 监听输入框
            inputValue(e, item) {
                let value = e.target.value
                if (item.id === 1) {
                    this.name = value
                    window.localStorage.setItem("name", this.name)
                }
                this.$set(this.answerList, item.id, {
                    id: item.id,
                    question_text: item.title,
                    answer_id: [value],
                    answer_text: [value]
                })
                setTimeout(() => {
                    console.log('xx')
                    // 解决ios移动端 软键盘收起后，页面内容被顶上去，不下滑回原处
                    const inputs = Array.from(document.querySelectorAll("input"));
                    inputs.forEach(input => input.addEventListener("blur", window.scroll(0, 0)));

                }, 100);
            },
            //单选事件，第一参题目数据，第二参答案数据，第三参当前页,以分组后的题目页
            handRadio(question, answer, page) {
                // 存储
                this.$set(this.answerList, question.id, {
                    id: question.id,
                    question_text: question.title,
                    answer_id: [answer.answer_id],
                    answer_text: [answer.text]
                })
                //当进行修改时，判断是否存在此题
                let result = this.answerList[question.id]
                if (result) {
                    this.answerList[question.id].answer_id[0] = answer.answer_id
                }
                //判断是否删除题目，或者添加
                this.setQuestionData(page, answer)
                this.moduleMove(page)
            },
            //多选事件，参数同上
            handCheckbox(question, answer,page) {
                let lastAnswer = question.answer[question.answer.length - 1]
                let result = this.answerList[question.id]
                if (result?.answer_id.length > 0) {
                    //判断清空
                    if (lastAnswer.answer_id === answer.answer_id && !result.answer_id.includes(lastAnswer.answer_id)) {
                        this.answerList[question.id] = {
                            id: question.id,
                            question_text: question.title,
                            answer_id: [answer.answer_id],
                            answer_text: [answer.text]
                        }
                        this.checkBtn(question.id,page)
                    } else {
                        //如果已经有了这个选项，则去除
                        if (result.answer_id.includes(answer.answer_id)) {
                            //过滤存储的id
                            let res = result.answer_id.filter((item) => {
                                return item != answer.answer_id
                            })
                            //过滤存储的答案文本
                            let res2 = result.answer_text.filter((item) => {
                                return item != answer.text
                            })
                            this.answerList[question.id].answer_id = res
                            this.answerList[question.id].answer_text = res2
                        } else {
                            //赋值，数组重构
                            let index = result.answer_id.indexOf(lastAnswer.answer_id)
                            if (index !== -1) {
                                result.answer_id.splice(index, 1)
                                result.answer_text.splice(index, 1)
                            }
                            //赋值，数组重构
                            this.answerList[question.id].answer_id = [...result.answer_id, answer.answer_id]
                            this.answerList[question.id].answer_text = [...result.answer_text, answer.text]
                        }
                    }

                } else {
                    this.$set(this.answerList, question.id, {
                        id: question.id,
                        question_text: question.title,
                        answer_id: [answer.answer_id],
                        answer_text: [answer.text]
                    })
                    if(lastAnswer.answer_id === answer.answer_id){
                        this.checkBtn(question.id,page)
                    }
                }
            },

            //多选事件按钮
            checkBtn(id, page) {
                let result = this.answerList[id]?.answer_id
                if (result) {
                    if (result.length > 0) {
                        this.moduleMove(page)
                    } else {
                        Toast('请选择')
                    }
                } else {
                    Toast('请选择')
                }
            },
            //输入框点击下一步,第三参判断是否限制
            inputNext(page, id, data) {

                let result = this.answerList[id]
                //查找到指定题目，判断有没有数据
                if (result?.answer_id[0]) {
                    //判断是否限制
                    if (data?.min && result.answer_id < data.min) {
                        Toast(data.min_text)
                    } else if (data?.max && result.answer_id > data.max) {
                        Toast(data.max_text)
                    } else {
                        this.moduleMove(page)
                    }
                } else {
                    if (id === 1) {
                        Toast('请告诉我们您的称呼~')
                    } else if (id === 3) {
                        Toast('请告诉我们您的年龄~')
                    } else if (id === 4) {
                        Toast('请告诉我们您的身高~')
                    } else if (id === 5) {
                        Toast('请告诉我们您的体重~')
                    } else {
                        Toast('请填写内容')
                    }

                }
            },
            //模块移动
            moduleMove(page) {
                //判断是否需要还原题目
                let newDeleteList = this.deleteList.filter((item) => {
                    //如果答案列表中没有符合被删题目的条件，进行还原
                    if (!this.answerList[item.question_id]?.answer_id.includes(item.answer_id)) {
                        //在题目列表查找，如果题目等于被删题目id，还原
                        this.list[item.page].map((value) => {
                            if (value.id == item.delete_id) {
                                value.is_delete = 0
                            }
                            return value
                        })
                    } else {
                        return item
                    }
                })
                this.deleteList = newDeleteList

                let currentModuleLength = this.list[page].filter(item => {
                    return !item.is_delete || item.is_delete == 0
                }).length

                if (this.pageActive[page] < currentModuleLength - 1) {
                    this.pageActive[page] += 1
                } else {
                    this.moduleActive += 1
                }
                //增加已读题目
                this.answerIndex += 1
                this.setProgress()
                //存储数据
                window.localStorage.setItem("answerData", JSON.stringify({
                    moduleActive: this.moduleActive,
                    pageActive: this.pageActive,
                    name: this.name,
                    answerList: this.answerList,
                    progress: this.progress,
                    answerIndex: this.answerIndex,
                    deleteList: this.deleteList

                }))

            },
            //删除或添加题目,第一参是第几个数组，第二数据
            setQuestionData(page, answer) {
                let {select, remove, question_id, answer_id} = answer
                //进行增加操作
                if (select?.length > 0) {
                    //获取当前题目id
                    let index = this.list[page].findIndex(data => data.id == question_id) + 1
                    console.log(index)
                    //查找数据，并添加
                    let res = this.data.data.forEach((item) => {
                        let findRes = select.forEach((value) => {
                            if (item.id === value.id) {
                                this.list[page].splice(index, 0, item)
                            }
                        })
                    })
                }
                //进行删除
                if (remove?.length > 0) {
                    //判断有没有前置条件
                    let res = this.list[page].map((item) => {
                        let findRes = remove.find((data) => {
                            //查看前置条件有没有符合
                            let isPre = true  //默认没有
                            if (data?.pre_id?.length > 0)
                                isPre = data.pre_id.every((cond) => {
                                    return this.answerList[cond.id]?.answer_id.includes(cond.answer_id)
                                })
                            return data.id == item.id && isPre
                        })
                        if (findRes) {
                            let str = {
                                question_id: question_id,
                                delete_id: findRes.id,
                                answer_id: answer_id,
                                page: page
                            }
                            item.is_delete = str
                            this.deleteList.push(str)
                        }
                        return item
                    })
                    this.list[page] = res
                }
            },
            //监听进度条
            setProgress() {
                //已答数量
                // let hasAnswerLength = Object.keys(this.answerList).length
                //总体数量，合并
                let questionLength = this.list.reduce((pre, cur) => {
                    let arr = pre.concat(cur)
                    return arr
                })
                //过滤
                let answerLength = questionLength.filter((item) => {
                    return !item.is_delete || item.is_delete == 0
                }).length
                console.log('总体数量' + answerLength)
                this.progress = parseInt((this.answerIndex / answerLength).toPrecision(2) * 100)
            },
            //返回上一步
            lastStep(page) {
                //设置进度条,删除上一条数据
                if (this.answerIndex > 0) {
                    this.answerIndex -= 1
                    let id = this.list[page][this.pageActive[page]].id
                    delete this.answerList[id]
                }

                if (this.pageActive[page] > 0) {
                    this.pageActive[page] -= 1
                } else {
                    if (this.moduleActive > 1) {
                        this.moduleActive -= 2
                    }
                }

                //总体数量，合并
                let questionLength = this.list.reduce((pre, cur) => {
                    let arr = pre.concat(cur)
                    return arr
                })
                //过滤
                let answerLength = questionLength.filter((item) => {
                    return !item.is_delete || item.is_delete == 0
                }).length

                this.progress = parseInt((this.answerIndex / answerLength).toPrecision(2) * 100)

                window.localStorage.setItem("answerData", JSON.stringify({
                    moduleActive: this.moduleActive,
                    pageActive: this.pageActive,
                    name: this.name,
                    answerList: this.answerList,
                    progress: this.progress,
                    answerIndex: this.answerIndex,
                    deleteList: this.deleteList
                }))
            }
        },

        watch: {
            moduleActive() {
                let length = (this.data.category.length) * 2  + 1
                if (this.moduleActive % 2 !== 0 &&this.moduleActive != length ) {
                    setTimeout(() => {
                        this.moduleActive += 1
                    }, 3000)
                }
            }
        },

        filters: {
            height(val) {
                let mi = parseInt(val / 100)
                let cm = val % 100
                return '= '+mi + '米' + cm
            },
            width(val) {
                let g = val * 2
                return '= ' + g + '斤'
            }
        }
    };
</script>

<style scoped lang="less">
    p{
        padding: 0;
        margin: 0;
    }
    .rule-con{
        padding: 20px 20px;
        box-sizing: border-box;
    }
    .rule-title{
        font-weight: 700;
        font-size: 16px;
        color: black;
        text-align: center;
        height: 40px;
    }
    .rule-info{
        font-size: 13px;
        color: black;
        line-height: 24px;
        height: calc(60vh - 80px);
        overflow: auto;
    }
   .home2{
        //font-family:'vant-icon' !important;
        font-size: 30px !important;
        margin-bottom: 40px !important;
        margin-top: -15vh;

    }
    /deep/ .yellow:before{
        background-color: #d6eb00 !important;
    }
    /deep/ .blue:before{
        background-color: #9194f7 !important;
    }
    /deep/ .red:before{
        background-color: #f96a8a !important;
    }
    .survey-box {
        height: 100%;
        overflow: hidden;
        font-size: 16px;

        .survey {
            width: 100%;
            height: 100%;
            display: flex;
            transition: all 0.5s;
        }
    }



    .transition-page,
    .answer-page {
        height: 100%;
        flex-shrink: 0;
        width: 100%;
        background: #f4f9f8;

        .home-title {
            font-size: 21px;
            font-weight: 900;
            //margin-bottom: 13px;
            font-family: sans-serif;
        }
    }

    //首页
    .transition-home {
        width: 100%;
        padding: 20px 15px;
        box-sizing: border-box;
        position: relative;

        &:before, &:after {
            content: '';
            display: block;
            position: absolute;
            width: 20%;
            height: 5px;
            background-color: black;
            top: -10px;
            left: 20px;
            border-radius: 50px;
        }

        &:after {
            top: unset;
            left: unset;
            bottom: 0px;
            right: 20px;
        }


        ul {
            font-size: 14px;
            line-height: 25px;
            margin-top: 30px;
            margin-bottom: 20px;
            padding-left: 0px;
            box-sizing: border-box;
            white-space: nowrap;
            font-weight: 700;

            span:before {
                background-color: #d5a3f9;
            }
        }

        .home-preson {
            margin-bottom: 10px;
            padding-left: 15px;
            font-weight: 700;
            p:first-of-type {
                font-size: 12px;
                line-height: 24px;
                font-weight: 400;
            }

            span {
                font-size: 12px;
                color: #000000;
                font-weight: 700;
            }

        }
    }

    .home-btn {
        margin: 0 auto;
        width: fit-content;
        background-color: #c270ff;
        color: white;
        border-radius: 10px;
        padding: 13px 30px;
        font-size: 18px;
        margin-top: 20px;
        margin-bottom: 20px;
        font-weight: 700;
    }

    /deep/ .van-checkbox__icon .van-icon {
        font-size: 12px;
    }

    /deep/ .van-checkbox__label {
        font-size: 12px;
        color: #666666;
    }

    //模块列表
    .module-list {
        margin-top: 30px;
        font-size: 19px;
        font-weight: 700;
        margin-left: -15px;
        li {
            margin-bottom: 50px;
            font-size: 22px;
            &:before {
                content: '';
                display: block;
                width: 23px;
                height: 23px;
                border: 2.5px solid;
                border-radius: 50%;
                margin-right: 20px;
            }

            &:first-of-type p:before {
                background-color: #9194f7;
            }

            &:nth-of-type(2) p:before {
                background-color: #f96a8a;
            }

            &:nth-of-type(3) p:before {
                background-color: #f7ed91;
            }
        }

    }

    //颜色
    /deep/ .sub-color {
        position: relative;
        width: fit-content;
        z-index: 5;
        font-weight: 900;
        margin: 0px 4px;
        //font-family: fantasy;
        font-family: sans-serif;
        &:before {
            content: '';
            display: block;
            width: calc(100%);
            height: 60%;
            top: 0;
            left: 0;
            z-index: -1;
            position: absolute;
            background-color: #bf60f9;
        }
    }

    //模块页面动画
    .transition-item {
        opacity: 0;
        animation: textmove 2s ease-in;
        animation-fill-mode: forwards;
        font-size: 19px;
        line-height: 35px;

        strong {
            font-size: 23px;
        }
    }


    .answer-page {
        padding: 20px 15px;
        box-sizing: border-box;
        height: 100%;

        display: flex;
        flex-direction: column;
        //题目盒子
        .problem {
            margin-top: 60px;
            width: 100%;
            position: relative;
            flex-shrink: 0;
            padding-bottom: 80px;

            .title {
                font-weight: 700;
                font-size: 20px;
                line-height: 28px;
                text-align: center;
                padding: 0px 20px;
                box-sizing: border-box;
            }
            .sub-title{
                color: #666666;
                font-size: 12px;
                margin-top: 10px;
                width: 100%;
                text-align: left;
                padding: 0px 20px;
                box-sizing: border-box;
            }

            .tag {
                font-size: 12px;
                color: #666666;
                position: absolute;
                bottom: 30px;
                left: 20px;
            }

            .banner {
                width: 40%;
                margin-bottom: 25px;
            }

            .problem-list {
                width: 78%;
                margin: 0 auto;
                overflow: auto;

                &::-webkit-scrollbar {
                    width: 0px;
                }
                //通用题目样式
                .problem-item {
                    font-weight: 700;
                    border: 2px solid;
                    text-align: center;
                    padding: 11px 8px;
                    margin-bottom: 20px;
                    border-radius: 40px;
                    box-sizing: border-box;
                    width: 100%;
                    font-size: 16px;
                }

                //高亮
                .problem-active {
                    color: white;
                    background: #292C2F;
                }

                //普通多选
                .problem-select {
                    width: 44%;
                }

                //圆形单选
                .problem-radio {
                    width: 100px;
                    height: 50px;
                }

                //输入框
                .input {
                    border: none;
                    border-bottom: 3px solid;
                    background-color: transparent;
                    text-align: center;
                    padding: 0px 30px 10px 30px;
                    width: 85%;
                    box-sizing: border-box;
                    font-size: 19px;
                    margin: 0 auto;
                    font-weight: 600;
                    margin-top: 60px;
                }
            }

            //单位
            .unit {
                position: absolute;
                bottom: 11px;
                right: 10px;
                margin: 0 auto;
                font-size: 20px;
                font-weight: 700;
            }

            .unit-tion {
                margin-top: 10px;
                color: #666666;
                font-size: 19px;
            }

            //小图标
            .icon {
                width: 30px;
                height: 30px;
                margin-right: 7px;
            }

            //确认按钮
            .btn {
                position: absolute;
                bottom: 20px;
                background-color: #c270ff;
                color: white;
                font-weight: 700;
                padding: 12px 0px;
                width: 80%;
                z-index: 999;
                text-align: center;
                opacity:1;
                border-radius: 40px;
            }
        }
    }

    // 进度条
    .progress-box {
        span {
            font-size: 14px;
            font-weight: bold;
            color: #232323;
            white-space: nowrap;
        }

        .progress {
            width: 100%;

            background: linear-gradient(90deg, #e9f1f0 0%, #e4eeec 100%);
            height: 20px;
            border-radius: 30px;
            margin: 0px 10px;
            overflow: hidden;

            .active-progress {
                width: 0%;
                height: 100%;
                transition: all 0.7s;
                background-color: #c270ff;
            }
        }
    }

    //文字加载动画
    @keyframes textmove {
        0% {
            opacity: 0;
            //transform: translateY(30px);
        }
        100% {
            opacity: 1;
            transform: translateY(0px);
        }
    }
</style>